.ant-btn {
    text-transform: uppercase;
    height: 38px;
    letter-spacing: .05em;
    font-size: 13px;
    border-radius: 2px;

    .anticon {
        vertical-align: text-top;
    }
}

.ant-btn-primary {
    background-color: $c-primary;
    border-color: $c-primary;

    &:hover, &:focus {
        background-color: lighten($c-primary,3);
        border-color: lighten($c-primary,3);
    }
}


.ant-btn-secondary:hover, .ant-btn-secondary:focus {
    color: #4f66a0;
    background: #fff;
    border-color: #4f66a0;
}


.ant-btn-danger {
    background-color: $danger;
    color: #fff;
    border-color: transparent;

    &:focus {
        color: #fff;
        background-color: darken($danger,5);
        border-color: transparent;
    }
}

.ant-dropdown-trigger > .anticon.anticon-down, .ant-dropdown-link > .anticon.anticon-down, .ant-dropdown-button > .anticon.anticon-down{
    margin-right: 0 !important;
}

.ant-btn:hover, .ant-btn:focus {
    color: #000;
    border-color: #f95e00;
}

.ant-input-affix-wrapper:hover {
    border-color: #f95e00;
    border-right-width: 1px !important;
    z-index: 1;
    box-shadow: none;
}

.ant-upload.ant-upload-select-picture-card:hover {
    border-color: #f95e00;
}