$bgColor:#fff;
$bodyColor:#f0f2f5;
$wht:#fff;

$k2:#fafafa;
$k3:#f7f7f7;
$k5:#f2f2f2;
$k10:#e6e6e6;
$k15:#d9d9d9;
$k20:#ccc;
$k25:#bfbfbf;
$k30:#b3b3b3;
$k40:#999;
$k50:#808080;
$k60:#666;
$k70:#4d4d4d;
$k80:#333;
$k90:#191919;
$k100:#000;

$base:#0071bc;
$txtColor:#696c74;

// $c-primary:#259dab; 
//$c-primary:#4F66A0;
$c-primary:#f95e00;
// $c-secondary:#464f63;
//$c-secondary:#DBE2EA;
$c-secondary:#DBE2EA;
$c-tertiary: #0092dd;

// $light:#f5f5f5;
$line: #ddd;
// $line2: #bdbdbd;
$darkBlue:#2e3192;
$hl: #ffcc00;
// $hl2: #e6e7ee;
// $blue: #0600e0;

$highlight:#5bbfde;
$danger: #d9534f;

$success:#a1ca51;
$error:#ce0000;

$purple:#444573;
$red:$error;
$yellow:#d5c93f;
$orange:orange;
$green:$success;
$gray:#5d737e;

// $font-stack: "Lato", sans-serif; 
$font-stack: 'Montserrat', sans-serif;