html {
	min-height:100%;
	height:100%;
}

body {
	background:$bodyColor;
	min-width:320px;
	height:100%;
}

#__next {
	height:100%;
}


.ant-layout-sider-dark {
	background: #000;
	position: fixed;
	z-index: 2;
	height: 100vh;
	overflow-y: auto;
}

.ant-layout-sider-dark::-webkit-scrollbar{
	width: 12px;
}

.ant-layout-sider-dark::-webkit-scrollbar-track {
		background-color: #ccc;
    border-radius: 0px;
}

.ant-layout-sider-dark::-webkit-scrollbar-thumb {
    border-radius: 0px;
		background-color: #000; 
}
​


#admin-layout {
	height: 100%;
	overflow: hidden;

	section {
		padding:20px 0;

		&:first-child {
			padding-top:0;
		}

		&:last-child {
			padding-bottom: 0;
		}

		&.ant-layout {
			padding: 0;
			overflow: hidden;
		}
	}

	.ant-layout-sider {
		background: #2c2738;
		min-height: 100vh;
		z-index: 150;
		overflow-x: hidden;
		overflow-y: auto;
	}

	.ant-layout-content {
		padding: 15px 15px 0;
		overflow-x: hidden;
		overflow-y: auto;

		> *:last-child {
			margin-bottom: 15px;
		}
	}
}

header.ant-layout-header {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	height: 61px;

	span {
		margin-top: 10px;
	}
}

.admin_header{
	@include media("<=lg"){
		padding-left: 20px !important;
	}
}

header.ant-layout-header {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	height: 61px;

	@include media("<=lg"){
		flex-direction: row;
		justify-content: center;
		z-index: 2 !important;
		align-items: center;

		> button{
			margin-right: auto;
		}

		.ant-dropdown-link{
			margin-left: auto;
		}


	}

	span {
		margin-top: 10px;
	}
}

header.ant-layout-header {
	//background: #2c2738;
	background: #000;
}

.ant-menu.ant-menu-dark,
.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark .ant-menu-sub {
	//background: #4f66a0;
	background: #f95e00;
}

.content-space {
	margin-top: 64px;
	margin-left: 210px;
}

@media only screen and (max-width: 992px) {
	.ant-layout-content {
		margin-left: 0px !important;
	}
}