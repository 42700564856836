button {
  background: none;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  padding: 0;
  user-select: none;
  cursor: pointer;

  &:focus {
    outline: none;
  }
}

.button-row {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  margin: 1.625em -0.375em;
  user-select: none;

  > * {
    margin: 0.375em;
  }

  &.center {
    justify-content: center;
  }

  .r-align,
  &.text-right > * {
    @include media('>=sm') {
      margin-left: auto;
    }
  }
}

.ant-input-search-button {
  height: 32px !important;
}

.select-folder {
  width: 250px;
  margin-right: 5px;
}

.new-gallery {
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 38px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
}

.gallery-upload-edit {
  display: flex;
  align-items: center;

  .select-folder {
    width: 250px;
    margin-inline: 5px;
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 38px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
}
