label {
  &.error {
    color: $error;
    font-size: 12px;
  }
}


.log {
  .card-wrapper {
    text-align: center;
    position: absolute;
    top: 40%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    min-width: 500px;

    @include media('<md') {
      min-width: 320px;
    }

    .ant-card-head-title {
      font-size: 18px;
      font-weight: 700;
      // color: #ffffff;
      color: #2c2638;
      font-weight: 100;
    }
  }
}

.dashboard {
  height: 100%;

  .card-wrapper {
    display: flex;
    height: 100%;

    .ant-card {
      width: 50%;

      @include media('<lg') {
        width: 100%;
      }

      .ant-card-body {
        padding: 10px 15px;
      }
    }
  }
}

.ant-form-item-explain div {
  float: left;
}

.ant-form-item.upload-wrapper.right {
  .ant-form-item-control {
    flex-direction: row;
    justify-content: flex-start;
  }

  .ant-upload-list-picture-card-container,
  .ant-upload.ant-upload-select-picture-card {
    margin: 0;
  }
}

.ant-card {
  &.login-header {
    .ant-card-head {
      color: #2c2638;
      background-color: #ffffff;
    }
  }
}

.ant-card-head {
  // background-color: #4f66a0;
  // color: #ffffff;
  // color: #2c2638;
  color: #ffffff;
  // background-color: #ffffff;
  background-color: #f95e00;
  text-transform: capitalize;

  .ant-card-head-title {
    //added
    text-transform: initial;
  }

}

.ant-picker-large {
  padding: 5px 11px 6px;
}


.panel-primary>.panel-heading {
  color: #ffffff;
  background-color: #f95e00;
  border-color: #f95e00;
}

.panel-heading .panel-title span {
  background-color: #f95e00 !important;
}

.log {
  .card-wrapper {
    .ant-card-head-title {
      text-align: center;
    }
  }
}

.ant-card {
  &.profile-title {
    .ant-card-head {
      background-color: #848587;
      color: #ffffff;
    }
  }
}

@media only screen and (max-width: 400px) {
  .ant-card-head {
    padding: 12px;
    font-size: 14px;
  }

  .ant-card-body {
    padding: 12px;
    font-size: 12px;
  }
}

.ant-form-item-label {
  min-width: 100px;
}

.log .card-wrapper .login-logo {
  margin-bottom: 30px;
  width: 300px;
}

input,
select {
  font-size: 100% !important;
}

input::-webkit-input-placeholder {
  font-size: 10px;
}

.slider-input {
  font-size: 12px !important;
}