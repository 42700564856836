.ant-statistic-content-prefix {
    .anticon {
        vertical-align: baseline;
        position: relative;
        top: -0.2ex;
    }
}

// .ant-checkbox-checked {
//     .ant-checkbox-inner {
//         background-color: #259dab !important;
//         border-color: #259dab !important;
//     }
// }


.ant-checkbox-checked {
    .ant-checkbox-inner {
        background-color: #f95e00 !important;
        border-color: #f95e00 !important;
    }
}

.ant-radio-checked::after{
    border: 1px solid #f95e00;
}

.ant-radio-checked .ant-radio-inner{
    border-color: #f95e00;
}

.ant-radio-inner:after {
    background-color: #f95e00 !important;
}

.ant-radio-wrapper:hover .ant-radio, .ant-radio:hover .ant-radio-inner, .ant-radio-input:focus + .ant-radio-inner {
    border-color: #f95e00;
}