.actions-block {
  padding: 10px 15px;
  // margin-bottom: 20px;
  margin-bottom: 8px;
  background-color: $c-secondary;
  // border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
