.h-100 {
  height: 100%;
}

.mh-100 {
  min-height: 100%;
}

.pt-0 {
  padding-top: 0 !important;
}

.inline-flex {
  display: flex;
  justify-content: space-between;
}

.loader {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999;
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px $orange;
  background-color: $orange;
  -webkit-box-shadow: inset 0 0 6px #f95e00;
  box-shadow: inset 0 0 6px #f95e00;
  background-color: #f95e00;
}
